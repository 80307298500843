import React from 'react';

// Components from MaterialUI
import { Grid, Card, Typography, Divider } from '@mui/material';

// Custom components
import IconCard from './IconCard';
import ScoreCard from './ScoreCard';

// Import icons
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import PedalBikeIcon from '@mui/icons-material/PedalBike';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ElectricScooterIcon from '@mui/icons-material/ElectricScooter';

const Traffic = () => {
  // Define styles for MaterialUI components
  const card_style = {
    justifyContent: 'center',
    alignItems: 'center',
    p: '16px',
    ml: '16px'
  }
  const typography_style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
  return (
    <>
      <Grid container spacing={2} sx={{px: '16px'}} direction='row' justifyContent='center' alignItems='center'>
        <Grid item xs={4}>
          <IconCard value={9851} icon={DirectionsCarIcon}></IconCard>
          <IconCard value={130} icon={TwoWheelerIcon}></IconCard>
        </Grid>
        <Grid item xs={4}>
          <IconCard value={128} icon={DirectionsBusIcon}></IconCard>
          <IconCard value={70} icon={LocalShippingIcon}></IconCard>
        </Grid>
        <Grid item xs={4}>
          <IconCard value={231} icon={ElectricScooterIcon}></IconCard>
          <IconCard value={216} icon={PedalBikeIcon}></IconCard>
        </Grid>

        <Grid item xs={4}>
          <Card variant='elevation' elevation={4} sx={card_style}>
            <Typography variant='h6' sx={{ ...typography_style, fontWeight: 'bold' }}>Vstupujúcich vozidiel</Typography>
            <Divider variant='middle' orientation='horizontal' flexItem ></Divider>
            <div style={typography_style}>
              <Typography variant='h4' sx={{ fontWeight: 'bold', color: 'green' }}>+</Typography>
              <Typography variant='h4' sx={{ fontWeight: 'bold' }}>727</Typography>
            </div>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card variant='elevation' elevation={4} sx={card_style}>
            <Typography variant='h6' sx={{ ...typography_style, fontWeight: 'bold' }}>Vystupujúcich vozidiel</Typography>
            <Divider variant='middle' orientation='horizontal' flexItem ></Divider>
            <div style={typography_style}>
              <Typography variant='h4' sx={{ fontWeight: 'bold', color: 'red' }}>-</Typography>
              <Typography variant='h4' sx={{ fontWeight: 'bold' }}>1473</Typography>
            </div>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <ScoreCard current_value={68} change_value={7} metric_name={'Skóre doprava'}></ScoreCard>
        </Grid>
      </Grid>
    </>
  );
};

export default Traffic;
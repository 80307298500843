import React from 'react';

// Components from MaterialUI
import { Card, Typography, Divider } from '@mui/material';

const PointsPerHourCard = ({ current_value, metric_name}) => {
  // Define styles for MaterialUI components
  const card_style = {
    justifyContent: 'center',
    alignItems: 'center',
    p: '4px',
    ml: '8px'
  }
  const typography_style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold'
  }

  return (
    <>
      <Card variant='elevation' elevation={4} sx={card_style}>
        <Typography variant='h5' sx={typography_style}>{metric_name}</Typography>
        <Divider variant='middle' orientation='horizontal' flexItem ></Divider>
        <Typography variant='h2' sx={typography_style}>{current_value}</Typography>
      </Card>
    </>
  );
};

export default PointsPerHourCard;
import React, { useState, useEffect } from 'react';

// Components from MaterialUI
import { Card, Grid, Typography, Divider } from '@mui/material';

// Import weather icons
import sun from '../assets/icons/weather_icons/sun.svg';
import fog from '../assets/icons/weather_icons/fog.svg';
import partly_cloudy from '../assets/icons/weather_icons/partly_cloudy.svg';
import cloudy from '../assets/icons/weather_icons/cloudy.svg';
import light_rain from '../assets/icons/weather_icons/light_rain.svg';
import moderate_rain from '../assets/icons/weather_icons/moderate_rain.svg';
import heavy_rain from '../assets/icons/weather_icons/heavy_rain.svg';
import snow from '../assets/icons/weather_icons/snow.svg';
import storm from '../assets/icons/weather_icons/storm.svg';
import clear_night from '../assets/icons/weather_icons/clear_night.svg';


const make_weather_dictionary = (weather_data) => {
  // This function fills dictionary with weather data or empty strings (while
  // data is not fetched yet)

  // For some information we need hour which will serve as an index 0-23
  const now = new Date();
  const currentHour = now.getHours();

  const weather = {}
  if(weather_data){
    weather.temperature = weather_data.current_weather['temperature']
    weather.uv = weather_data.daily['uv_index_max'][0]
    weather.wind_speed = weather_data.current_weather['windspeed']
    weather.precipitation_prob = weather_data.hourly['precipitation_probability'][currentHour]
    weather.weathercode = weather_data.hourly['weathercode'][currentHour]
    weather.day = weather_data.hourly['is_day'][currentHour]
  }
  else{
    weather.temperature = ''
    weather.uv = ''
    weather.wind_speed = ''
    weather.precipitation_prob = ''
    weather.weathercode = ''
    weather.day = ''
  }
  return weather
}

const WeatherCard = ({ town_name = 'Dolný Kubín', latitude = '49.2098', longitude = '19.3034' }) => {
  // Fetch weather data
  const [weather_data, set_weather_data] = useState(null); // State to store weather data

  useEffect(() => {
    // API url
    const api_url = `https://api.open-meteo.com/v1/forecast?` +
      `latitude=${latitude}&` +
      `longitude=${longitude}&` +
      `hourly=precipitation_probability,` +
      `weathercode,` +
      `is_day&` +
      `daily=uv_index_max&` +
      `current_weather=true&` +
      `timezone=Europe%2FBerlin&` +
      `forecast_days=1`;

    // Fetch weather data from API
    const fetch_weather_data = async () => {
      // Make an API request here to fetch weather data
      return fetch(api_url)
        .then(response => response.json())
        .catch(error => {
          console.error(error);
          throw error;
        });
    };

    fetch_weather_data()
      .then(data => set_weather_data(data))
      .catch(error => console.error(error));
  }, [latitude, longitude]);

  const weather = make_weather_dictionary(weather_data)

  // Choose corresponding weather icon based on weather code
  let icon;
  if (weather.weathercode === 0) {
    if (weather.day)
      icon = sun;
    else
      icon = clear_night;
  } else if ([1, 2].includes(weather.weathercode)) {
    icon = partly_cloudy;
  } else if ([45, 48].includes(weather.weathercode)) {
    icon = fog;
  } else if ([51, 53, 55].includes(weather.weathercode)) {
    icon = light_rain;
  } else if ([61, 63, 65].includes(weather.weathercode)) {
    icon = moderate_rain;
  } else if ([80, 81, 82].includes(weather.weathercode)) {
    icon = heavy_rain;
  } else if ([95, 96, 99].includes(weather.weathercode)) {
    icon = storm;
  } else if ([56, 57, 66, 67, 71, 73, 75, 77, 85, 86].includes(weather.weathercode)) {
    icon = snow;
  } else {
    icon = cloudy;
  }

  // Define styles for MaterialUI components
  const card_style = {
    justifyContent: 'center',
    alignItems: 'center'
  }
  const typography_style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold'
  }

  return (
    <>
      <Card variant='elevation' elevation={4} sx={card_style}>
        <Typography variant='h5' sx={typography_style}>Počasie {town_name}</Typography>
        <Divider variant='middle' orientation='horizontal' flexItem ></Divider>
        <Grid container spacing={1} direction='row' justifyContent='center' alignItems='center'>
          <Grid item xs={3}>
            <img src={icon} alt='Weather Icon' style={{ width: '70px' }} />
          </Grid>
          <Grid item xs={5}>
            <Typography variant='h3' sx={typography_style}>{weather.temperature}°C</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='h6' sx={typography_style}>{weather.uv} UV</Typography>
            <Typography variant='h6' sx={typography_style}>{weather.precipitation_prob}%</Typography>
            <Typography variant='h6' sx={typography_style}>{weather.wind_speed} km/h</Typography>
          </Grid>
        </Grid>
        <Typography variant='h5' sx={{ ...typography_style, bgcolor: '#ffb5b5' }}>SHMU: Búrky(2) Teplota(2)</Typography>
      </Card>
    </>
  );
};

export default WeatherCard;
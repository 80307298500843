// Components from MaterialUI
import { Grid, Typography, List} from '@mui/material';
import Story from './Story';

// Custom components


const News = () => {
  const typography_style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
  return (
    <>
      <Grid container spacing={2} sx={{ mt: '8px' }}>
        <Grid item xs={12}>
          <Typography variant='h3' sx={typography_style}>Správy</Typography>
        </Grid>
      </Grid>

      <List dense={true} sx={{maxHeight: '360px', overflow: 'auto'}}>
        <Story story_type='AI'></Story>
        <Story story_type='MSG'></Story>
        <Story story_type='ALERT'></Story>
        <Story story_type='MSP'></Story>
        <Story story_type='INCDT'></Story>
        <Story story_type='AI'></Story>
      </List>
    </>
  );
};

export default News;
// Components from MaterialUI
import { Grid, Card, Typography } from '@mui/material';

// Custom components
import ScoreCard from './ScoreCard';

const Energy = () => {
  // Define styles for MaterialUI components
  const card_style = {
    justifyContent: 'center',
    alignItems: 'center',
    p: '16px',
    ml: '16px',
    mb: '16px'
  }
  const typography_style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold'
  }

  return (
    <>
      <Grid container spacing={2} direction='row' justifyContent='center' alignItems='center' sx={{ marginTop: '32px', px: '16px' }}>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
              <Card variant='elevation' elevation={4} sx={card_style}>
                <Typography variant='h5' sx={typography_style}>Zdanlivý výkon: 1232 kVA</Typography>
                <Typography variant='h5' sx={typography_style}>Jalový výkon: 1232 kVAr</Typography>
                <Typography variant='h5' sx={typography_style}>L1: 0.33 MW</Typography>
                <Typography variant='h5' sx={typography_style}>L2: 0.33 MW</Typography>
                <Typography variant='h5' sx={typography_style}>L3: 0.33 MW</Typography>
              </Card>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
              <Card variant='elevation' elevation={4} sx={card_style}>
                <Typography variant='h4' sx={typography_style}>12320 MW</Typography>
              </Card>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <ScoreCard current_value={80} change_value={-12} metric_name={'Skóre energia'}></ScoreCard>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </>
  );
};

export default Energy;
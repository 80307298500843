// Components from MaterialUI
import { Grid } from '@mui/material';

// Custom components
import ScoreCard from './ScoreCard';
import InformationCard from './InformationCard';
import PointsPerHourCard from './PointsPerHourCard';
import WeatherCard from './WeatherCard';

const Cumulative = () => {
  return (
    <>
      <Grid container spacing={2} direction='row' justifyContent='center' alignItems='center' sx={{px: '16px'}}>
        {/* First row with all scores and cumulative score */}
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InformationCard current_value={80} metric_name={'Energie'}></InformationCard>
            </Grid>
            <Grid item xs={6}>
              <InformationCard current_value={68} metric_name={'Doprava'}></InformationCard>
            </Grid>
            <Grid item xs={6}>
              <InformationCard current_value={39} metric_name={'Počasie'}></InformationCard>
            </Grid>
            <Grid item xs={6}>
              <InformationCard current_value={7} metric_name={'Osvetlenie'}></InformationCard>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <ScoreCard current_value={50} change_value={-7} metric_name='Kumulatívne skóre'></ScoreCard>
        </Grid>


        {/* Second row with points per hours and weather */}
        <Grid item xs={3}>
          <PointsPerHourCard current_value={27} metric_name='Za 3 hodiny'></PointsPerHourCard>
        </Grid>
        <Grid item xs={3}>
          <PointsPerHourCard current_value={84} metric_name='Za 12 hodín'></PointsPerHourCard>
        </Grid>
        <Grid item xs={6}>
          <WeatherCard></WeatherCard>
        </Grid>
      </Grid>
    </>
  );
};

export default Cumulative;
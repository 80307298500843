import React from 'react';

// Components from MaterialUI
import { Card, Typography, Divider } from '@mui/material';

const IconCard = ({ value, icon: IconComponent }) => {
  // Define styles for MaterialUI components
  const card_style = {
    justifyContent: 'left',
    alignItems: 'center',
    display: 'flex',
    p: '16px',
    ml: '8px',
    mb: '16px'
  }
  const icon_style = {
    fontSize: 60,
    mr: '8px',
    color: '#3cb4ff'
  }
  const right_align = {
    flex: 1,
    textAlign: 'right'
  }

  return (
    <>
      <Card variant='elevation' elevation={4} sx={card_style}>
        <IconComponent sx={icon_style}/>
        <Divider orientation='vertical' flexItem ></Divider>
        <Typography variant='h4' sx={right_align}>{value}</Typography>
      </Card>
    </>
  );
};

export default IconCard;
import React from 'react';

// Components from MaterialUI
import { Card, Typography } from '@mui/material';

const ScoreCard = ({ current_value, change_value, metric_name = 'Skóre'}) => {
  // Determine color and +/- sign depending on change_value 
  let change_color = 'green'
  if (change_value >= 0){
    change_value = '+' + change_value.toString();
  }
  else{
    change_value = change_value.toString();
    change_color = 'red'
  }

  // Define styles for MaterialUI components
  const card_style = {
    justifyContent: 'center',
    alignItems: 'center',
    p: '16px',
    ml: '16px'
  }
  const typography_style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold'
  }
  const sign_color = {
    color: change_color
  }

  return (
    <>
      <Card variant='elevation' elevation={4} sx={card_style}>
        <Typography variant='h5' sx={typography_style}>{metric_name}</Typography>
        <Typography variant='h3' sx={typography_style}>{current_value}%</Typography>
        <Typography variant='h5' sx={typography_style}>Zmena</Typography>
        <Typography variant='h4' sx={{ ...typography_style, ...sign_color }}>{change_value}%</Typography>
      </Card>
    </>
  );
};

export default ScoreCard;
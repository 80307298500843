import React from 'react';

// Components from MaterialUI
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Custom components
import Traffic from './components/Traffic';
import Energy from './components/Energy';
import Cumulative from './components/Cumulative';
import News from './components/News';

function App() {
  // Get the current date
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().split('T')[0]
  return (
    <Grid container spacing={2} sx={{bgcolor: '#ebebeb'}}>
      {/* Headlines */}
      <Grid item xs={12} textAlign={'center'}>
        <Typography variant='h2'>Celková situácia Dolný Kubín</Typography>
        <Typography variant='h5'>Dataview ID: 2727 | Aktualizované {formattedDate}</Typography>
      </Grid>

      {/* Left side with information about traffic and energy*/}
      <Grid item xs={12} lg={6} textAlign={'center'} sx={{mt: '16px'}}>
        <Typography variant='h3' sx={{mb: '16px'}}>Doprava za posledných 30 minút</Typography>
        <Traffic/>
        <Energy/>
      </Grid>

      {/* Right side with all metrics, weather and news */}
      <Grid item xs={12} lg={6} textAlign={'center'} sx={{mt: '16px'}}>
        <Typography variant='h3' sx={{mb: '16px'}}>Informácie</Typography>
        <Cumulative/>
        <News/>
      </Grid>

    </Grid>
  );
}

export default App;
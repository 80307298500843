import React from 'react';

// Components from MaterialUI
import { Card, Divider, ListItem, ListItemAvatar, ListItemText, Avatar } from '@mui/material';

// Import icons
import SmartToyIcon from '@mui/icons-material/SmartToy';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import MessageIcon from '@mui/icons-material/Message';
import EmailIcon from '@mui/icons-material/Email';

const Story = ({
  created_by_message = 'Test: vytvorené 30.8.2023 používateľom MSP',
  story_content = `Toto je testovací/defaultný obsah správy, ktorá sa tu zobrazuje.
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent interdum, urna
  at tempor pretium, justo sem malesuada quam, at efficitur enim elit eu velit.`,
  story_type = 'ALERT'
}) => {

  // Dictionary that maps story type to corresponding colored icon
  const story_type_dictionary = {
    AI: [SmartToyIcon, '#bd61ff'],
    ALERT: [PriorityHighIcon, '#ffb254'],
    INCDT: [ReportProblemIcon, '#ff6161'],
    MSG: [MessageIcon, '#61a3ff'],
    MSP: [EmailIcon, '#61ff64']
  }

  const story_type_style = story_type_dictionary[story_type]
  // We need icon component in its own variable which is named using PascalCase
  const StoryTypeIcon = story_type_style[0]

  // Define styles for MaterialUI components
  const card_style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  }

  return (
    <>
      <ListItem>
        <Card variant='elevation' elevation={4} sx={card_style}>
          <ListItemAvatar sx={{ minWidth: '0px', m: '5px' }}>
            <Avatar sx={{ bgcolor: story_type_style[1] }}>
              <StoryTypeIcon />
            </Avatar>
          </ListItemAvatar>
          <Divider orientation='vertical' variant='middle' sx={{ mx: '5px' }} flexItem ></Divider>
          <ListItemText primaryTypographyProps={{noWrap: true}} primary={story_content} secondary={created_by_message} sx={{mr:'5px'}} />
        </Card>
      </ListItem>
    </>
  );
};

export default Story;